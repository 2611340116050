<template>
  <div class="solution-home">
    <InspectionRecords></InspectionRecords>
  </div>
</template>

<script>
import InspectionRecords from './components/InspectionRecords'

export default {
  name: 'PacsInspection',
  components: {
    InspectionRecords
  },
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="less">
.solution-home {
  // width: 100vw;
  height: 100vh;
}
</style>
